import { Pipe, PipeTransform } from '@angular/core';
import { LOCALSTORAGE_TOKEN_KEY } from '@core/constants';
import { environment as env } from '../../../environments/environment';

@Pipe({ name: 'tokenized', standalone: true, pure: false })
export class TokenPipe implements PipeTransform {
  static instance: TokenPipe;

  static transform(src: string): string {
    this.instance ||= new TokenPipe();

    return this.instance.transform(src);
  }

  public transform(src: string = null): string {
    if (!src) {
      return null;
    }

    if (!src.startsWith('http')) {
      if (src.startsWith('/')) {
        src = src.slice(1);
      }

      src = [env.apiUrl, src].join('/');
    }

    const url = new URL(src);
    url.searchParams.set('token', localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));

    return url.toString();
  }
}
